import React, { useState } from "react";
import { ArrowRight } from 'react-bootstrap-icons'

function ReadMoreLess() {
    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    return (
        <div className="ReadMoreSection">
            <h4 className='m-0'>Abhee Developers</h4>
            
            <p className="mb-0">
                <h6>VISION THAT DRIVES US FORWARD</h6>
                <p className="AboutExpoPara">As India races fast into the future, we are evolving to cater to its demands and create real estate solutions. With cities swarming with IT parks, MNCs, MSMEs, and startups, to name a few, we create hubs of comfort, connectivity, and convenience more prescient than the rest.</p>

                <h6>A JOURNEY TOWARD IMMORTALITY</h6>
                <p className="AboutExpoPara">From our humble beginnings, we’ve continued to march towards a prosperous future that will see us becoming, for all intents and purposes, an immortal brand.</p>
            </p>

            {isShowMore && (
                <p className="mb-0">
                    <h6>LEADERSHIP</h6>
                    <p className="AboutExpoPara">We are a group of young, vivacious, privately held entrepreneurs who are devoted, real estate enthusiasts. ABHEE Ventures is led by R NAGARAJ REDDY and has its headquarters in Bangalore. For us, the phrase "TURNING LAND INTO LANDMARKS" is more than just a catchphrase; it embodies our attitude of turning land into vibrant, living landmarks. From having a vision to incorporating the vision into the design philosophy and bringing the design philosophy to life by balancing various aspects like blending the natural landscape features with the functional requirements to highlighting particular features to using subtlety with precision and perfection, creating landmarks involves many parameters.</p>
                </p>
            )}

            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>
        </div>
    );
}

export default ReadMoreLess;