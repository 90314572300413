
import React, { useState } from "react"
import "./assets/styles/_index.scss"
import AbheeAboutSidebar from "../../developers/abheedeveloper/AbheeAboutSidebar";
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import { Link } from "gatsby"
import ProjectCtaButton from "../../projectctabutton/ProjectCtaButton"
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { Accordion, Card, Button } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import VideoPlayer from "../../videoplayer/VideoPlayer"
import Slider from "react-slick"
import ProptvStand from "../../proptvstand/ProptvStand"

const videoUrl = "https://www.propverse.ai/utility/assets/abhee-developer/Abhee-video.mp4"
const thumbnailUrl = require("/utility/assets/common/video-thumbnail.png")

const propGpt = require("/utility/assets/common/propGPT.png")
const project = require("/utility/assets/icons/project-list.png")
const about = require("/utility/assets/icons/about-developer.png")
const enquiry = require("/utility/assets/icons/enquiry.png")

const videoUrlStand ="https://www.propverse.ai/utility/assets/abhee-developer/Abhee-video.mp4"
const thumbnailUrlStand = require("/utility/assets/homepage/prop-tv.png")

function AbheeAbout() {
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);

    const toggleRightSidebar = () => {
        setIsRightSidebarOpen(isRightSidebarOpen);
    };

    const [isShowMore, setIsShowMore] = useState(false);

    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: "0px",
        centerMode: true,
    
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              dots: false,
              centerMode: true,
              centerPadding: "0px",
              slidesToShow: 1,
            },
          },
    
        ],
    
      }

    return (
        <>

            <div className="ProjectAboutPageSectionAbhee">
                <Container fluid className="BoothBackSection d-none d-md-none">
                    <Link to="/developers/abhee-developer/">
                        <ArrowLeft /> Back
                    </Link>

                    <div className="EnquiryBoothSection d-block d-md-none">
                        <Image src={enquiry} fluid className="" />
                    </div>
                </Container>

                <div className="d-none d-md-block">
                    <AbheeAboutSidebar
                        isOpen={isRightSidebarOpen}
                        toggleRightSidebar={toggleRightSidebar}
                    />
                </div >

                <ProjectCtaButton />

                <div className="proptv-stand-wrapper d-none d-md-block">
                    <ProptvStand
                        videoUrlStand={videoUrlStand}
                        thumbnailUrlStand={thumbnailUrlStand}
                    />
                </div>

                <Container fluid className="ProjectAboutDetailFooterSection">
                    <Row>
                        <Col lg={4} className="p-0 textAlignCenter">
                        </Col>
                        <Col lg={4} className="p-0 textAlignCenter">
                            <Row className="ProjectAboutDetailListBg d-none d-md-block">
                                <Col lg={6} xs={7} className="p-0 pr-3 textAlignCenter">
                                    <div className="ProjectAboutDetailList">
                                        <Link to="/developers/abhee-developer/">
                                            <p><Image src={project} fluid className="" /> Project List</p>
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg={6} xs={7} className="p-0 pr-3 pr-md-0 textAlignCenter">
                                    <div className="AboutDev">
                                        <Link to="/developers/abhee-developer/about/">
                                            <p><Image src={about} fluid className="" /> About&nbsp;Developer</p>
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className="p-0 textAlignRight">
                            <Image src={propGpt} fluid className="BoothLogoSection" />
                        </Col>
                    </Row>
                </Container>

                <Accordion defaultActiveKey="0" className="d-block d-md-none ProjectListAccordion ProjectDetailsList">

                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Abhee Developers
                            </Accordion.Toggle>
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Tabs
                                    defaultActiveKey="aboutus"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                                >
                                    <Tab eventKey="aboutus" title="About Us">
                                        <div className="ReadMoreSection">
                                            <h4 className='m-0'>Abhee Developers</h4>
                                            <p className="mb-0">
                                                <h6>VISION THAT DRIVES US FORWARD</h6>
                                                <p className="AboutExpoPara">As India races fast into the future, we are evolving to cater to its demands and create real estate solutions. With cities swarming with IT parks, MNCs, MSMEs, and startups, to name a few, we create hubs of comfort, connectivity, and convenience more prescient than the rest.</p>
                                            </p>

                                            {isShowMore && (
                                                <p className="mb-0">
                                                    <h6>A JOURNEY TOWARD IMMORTALITY</h6>
                                                    <p className="AboutExpoPara">From our humble beginnings, we’ve continued to march towards a prosperous future that will see us becoming, for all intents and purposes, an immortal brand.</p>

                                                    <h6>LEADERSHIP</h6>
                                                    <p className="AboutExpoPara">We are a group of young, vivacious, privately held entrepreneurs who are devoted, real estate enthusiasts. ABHEE Ventures is led by R NAGARAJ REDDY and has its headquarters in Bangalore. For us, the phrase "TURNING LAND INTO LANDMARKS" is more than just a catchphrase; it embodies our attitude of turning land into vibrant, living landmarks. From having a vision to incorporating the vision into the design philosophy and bringing the design philosophy to life by balancing various aspects like blending the natural landscape features with the functional requirements to highlighting particular features to using subtlety with precision and perfection, creating landmarks involves many parameters.</p>
                                                </p>
                                            )}
                                            <p className="m-0 AboutExpoButton" onClick={toggleReadMoreLess}>{isShowMore ? "Read Less" : "Read More"} <ArrowRight /></p>

                                            <div className="AboutHallSection">
                                                <Button>Click here to download brochure</Button>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="video" title="Video">
                                        <section className="pt-0">
                                            <Container className="p-0">
                                                <Slider {...settings} className="">
                                                    <div className="ProjectListAccordionSlider">
                                                        <VideoPlayer
                                                            videoUrl={videoUrl}
                                                            thumbnailUrl={thumbnailUrl}
                                                        />
                                                    </div>
                                                </Slider>
                                            </Container>
                                        </section>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                </Accordion>
            </div >
        </>
    )
}

export default AbheeAbout