import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import AbheeAbout from "../../../components/developers/abheedeveloper/AbheeAbout";
import TagManager from 'react-gtm-module'

const favicon_logo = require("../../../images/favicon.png");

const AbheeAboutPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <AbheeAbout />
    </>
  )
}

export default AbheeAboutPage
